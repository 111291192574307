import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { flowRight as compose } from 'lodash';
// import Paper from '@material-ui/core/Paper';
// import DashboardReports from './DashboardReports';
import DashboardViolations from './DashboardViolations';
import DashboardChildAlerts from './DashboardChildAlerts';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '100%'
  }
});

const DashboardPage = () => {
  const render = () => {
    return (
      <div>
        <em>Chart data currently unavailable.</em>
        {/* <Paper>
          <DashboardReports />
        </Paper> */}
        <DashboardViolations />
        <DashboardChildAlerts />
      </div>
    );
  };

  return render();
};

export default compose(withStyles(styles))(DashboardPage);
