import DashboardPage from './components/modules/Dashboard/DashboardPage';
import ChildPage from './components/modules/Child/ChildPage';
import CompletedSurveyPage from './components/modules/CompletedSurvey/CompletedSurveyPage';
import CheckupPage from './components/modules/Checkup/CheckupPage';
import ChildrenPage from './components/modules/Children/ChildrenPage';
import ManageSurveyPage from './components/modules/ManageSurvey/ManageSurveyPage';
import UsersPage from './components/modules/Users/UsersPage';
import SettingsPage from './components/modules/Settings/SettingsPage';
import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import NotesIcon from '@material-ui/icons/Notes';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import NoteIcon from '@material-ui/icons/Note';
import Reports from './components/modules/Reports/Reports';
import ChildByOutsideSystemIdPage from 'components/modules/Child/ChildByOutsideSystemIdPage';

export default [
  {
    path: '/',
    component: DashboardPage,
    menuItem: true,
    name: 'Dashboard',
    icon: <HomeIcon />
  },
  // {
  //   path: '/login',
  //   component: LoginPage,
  //   requiresAuth: false
  // },
  {
    path: '/child/:id',
    component: ChildPage,
    requiresAuth: true
  },
  {
    path: '/survey/:id/:surveyTemplateId',
    component: CompletedSurveyPage
  },
  {
    path: '/checkup/:id',
    component: CheckupPage
  },
  {
    path: '/children/:outsideSystemId',
    component: ChildByOutsideSystemIdPage,
    requiresAuth: true
  },
  {
    path: '/children',
    component: ChildrenPage,
    menuItem: true,
    name: 'Children',
    icon: <ChildCareIcon />
  },
  {
    path: '/manage-survey',
    component: ManageSurveyPage,
    menuItem: true,
    name: 'Manage Surveys',
    icon: <NotesIcon />,
    permissions: ['SUPERADMIN']
  },
  {
    path: '/users',
    component: UsersPage,
    menuItem: true,
    name: 'Users',
    icon: <PeopleIcon />
  },
  {
    path: '/settings',
    component: SettingsPage
  },
  {
    path: '/reports',
    component: Reports,
    menuItem: true,
    name: 'Reports',
    icon: <NoteIcon />
  }
];
