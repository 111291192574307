import { gql } from '@apollo/client';
import { BaseChildFragment } from '../Fragments/child-fragment';

export default gql`
  query($outsideSystemId: Int) {
    child(outsideSystemId: $outsideSystemId) {
      ...BaseChild
      avatar
      child_alerts {
        id
        message
        createdOnDate
        submittedOnDate
        surveyCompletedId
        isAcknowledged
      }
      child_imgs {
        id
        imgUrl
        isFromMobile
        isApproved
        createdOnDate
        outsideSystemId
      }
      child_messages {
        id
        message
        isApproved
        createdOnDate
        submittedOnDate
        surveyCompletedId
      }
      sponsor_messages {
        id
        message
        isApproved
        outsideSystemId
        createdOnDate
      }
      checkups {
        id
        message1
        message2
        createdOnDate
        submittedOnDate
        user {
          id
          firstName
          lastName
        }
      }
      survey_completeds {
        id
        createdOnDate
        submittedOnDate
        surveyTemplateId
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
  ${BaseChildFragment}
`;
